import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { PopupTop } from "../../ui/Popup/PopupTop";
import { useInput } from "../../hooks/useInput";
import { SvgSprite } from "../../ui/SvgSprite/SvgSprite";
import { Select } from "../../ui/Select/Select";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { axiosInstance } from "../../config/https";
import { Btn } from "../../ui/Btn/Btn";
import { Actions } from "../../ui/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { ProfileImage } from "../../ui/ProfileImage/ProfileImage";
import { checkErrors } from "../../functions/checkErrors";
import { Input } from "../../ui/Input/Input";
import { Loader } from "../../ui/Loader/Loader";
import { getEvents } from "../../functions/getEvents";
import { toast } from "../../functions/toast";
import { getIncidentReport } from "../../functions/getIncidentReport";
import { getResources } from "../../functions/getResources";
import AddAttachments from "./AddAttachments";

export const AddEventComponent = ({
  closePopup,
  title,
  newEvent,
  setEvents,
  events,
  updateEvent,
  stateData,
  openWithData,
  report_id,
  setReturnReportId,
  report_title,
  report,
}) => {
  const now = newEvent ? new Date(newEvent?.start || newEvent?.startTime) : new Date();

  const users = useSelector(state => state.organization.org.users);
  const [file, setFile] = useState([]);
  const [resourcesIds, setResourcesIds] = useState([]);

  const [newValue, setNewValue] = useState();
  const [StartError, setStartError] = useState(false);
  const [EndError, setEndError] = useState(false);
  const dispatch = useDispatch();
  const [start, setStartDate] = useState(now);
  const [end, setEndDate] = useState(now);

  const [startHour, setStartHour] = useState(updateEvent ? new Date(newEvent?.startHour || newEvent?.startTime) : now);
  const [endHour, setEndHour] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59));
  const titre = useInput(updateEvent ? newEvent?.title : "");
  const note = useInput(updateEvent ? newEvent?.note : "");
  const [openListUsers, setOpenListUsers] = useState(false);
  const [listMembers, setListMembers] = useState(updateEvent ? newEvent?.members : []);
  const [errors, setErrors] = useState({});
  const [eventLoader, setEventLoader] = useState(false);
  const [deletEventLoader, setDeleteEventLoader] = useState(false);
  const listuser = useRef();
  const [completed_at, setCompleted_at] = useState(newEvent?.completed_at ? true : false);
  const [dotsLoader, setDotsLoader] = useState(false);
  const [colors] = useState([
    {
      id: 0,
      name: (
        <div className={styles.colorType}>
          <div
            className={styles.typePlays}
            style={{ backgroundColor: "#8833FF" }}
          >
            <div className={styles.typecercel}></div>
          </div>
          General
        </div>
      ),
      value: "#8833FF",
      color: "#8833FF",
    },
    {
      id: 1,
      name: (
        <div className={styles.colorType}>
          <div
            className={styles.typePlays}
            style={{ backgroundColor: "#E62E2E" }}
          >
            <div className={styles.typecercel}></div>
          </div>
          Audit
        </div>
      ),
      value: "#E62E2E",
      color: "#E62E2E",
    },
    {
      id: 2,
      name: (
        <div className={styles.colorType}>
          <div
            className={styles.typePlays}
            style={{ backgroundColor: "#33BFFF" }}
          >
            <div className={styles.typecercel}></div>
          </div>
          Review
        </div>
      ),
      value: "#33BFFF",
      color: "#33BFFF",
    },
    {
      id: 3,
      name: (
        <div className={styles.colorType}>
          <div
            className={styles.typePlays}
            style={{ backgroundColor: "#29CC39" }}
          >
            <div className={styles.typecercel}></div>
          </div>
          Task
        </div>
      ),
      value: "#29CC39",
      color: "#29CC39",
    },
    {
      id: 4,
      name: (
        <div className={styles.colorType}>
          <div
            className={styles.typePlays}
            style={{ backgroundColor: "#000000" }}
          >
            <div className={styles.typecercel}></div>
          </div>
          Incident
        </div>
      ),
      value: "#000000",
      color: "#000000",
    },
  ]);

  const handleClick = e => {
    if (openListUsers && !listuser.current.contains(e.target)) {
      setOpenListUsers(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openListUsers]);

  const addEventAction = async () => {
    setEventLoader(true);

    var path = report ? "event/incident/report" : "events";

    if (start) {
      setStartError(false);
    }
    if (end) {
      setEndError(false);
    }
    if (!start || !end) {
      !start && setStartError(true);
      !end && setEndError(true);
      setEventLoader(false);
      return;
    }

    const inputsInfo = [{ el: titre, errorName: "titre", errorText: "Enter Event Title" }];

    if (await checkErrors(setErrors, inputsInfo)) {
      setEventLoader(false);
      return;
    }

    if (startHour && endHour) {
      start.setHours(startHour ? startHour.getHours() : 0, startHour ? startHour.getMinutes() : 0);
      end.setHours(endHour ? endHour.getHours() : 0, endHour ? endHour.getMinutes() : 0);
    }

    const ev = {
      title: titre.value,
      note: note.value,
      color: newValue,
      startTime: start.getTime() / 1000,
      endTime: end.getTime() / 1000,
      members:
        listMembers.map(u => {
          return u.id;
        }) || [],
      controlSubmissionsId: stateData?.controlSubmissions?.id,
      control_id: stateData?.control?.id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      report_id: report_id,
      completed_at: completed_at,
      attachments: file,
      resourcesIds: resourcesIds?.map(a => {
        return a.id;
      }),
    };

    await axiosInstance
      .post(path, ev, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async res => {
        setReturnReportId(res.data?.report_id);
        await dispatch(getEvents());
        dispatch(toast("success", "Event Added Successfully"));
      })
      .catch(err => {
        dispatch(toast("error", err?.response?.data?.message));
      })
      .finally(() => {
        setEventLoader(false);
        if (report) {
          dispatch(getIncidentReport());
        }
        closePopup();
      });
  };

  useEffect(() => {
    (async () => {
      setDotsLoader(true);
      await dispatch(getResources());
      setDotsLoader(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateEventAction = async () => {
    setEventLoader(true);
    const inputsInfo = [{ el: titre, errorName: "titre", errorText: "Enter Event Title" }];

    if (await checkErrors(setErrors, inputsInfo)) {
      setEventLoader(false);
      return;
    }

    if (start) {
      setStartError(false);
    }
    if (end) {
      setEndError(false);
    }

    if (!start || !end) {
      !start && setStartError(true);
      !end && setEndError(true);
      setEventLoader(false);
      return;
    }

    if (startHour) {
      start.setHours(startHour?.getHours(), startHour?.getMinutes());
      end.setHours(endHour?.getHours(), endHour?.getMinutes());
    }
    const ev = {
      endHour: new Date(endHour),
      startHour: new Date(startHour),
      end: new Date(end),
      start: new Date(start),
      title: titre.value,
      note: note.value,
      color: newValue,
      dataCreated: "test",
      lastUpdated: "test",
      startTime: new Date(start),
      endTime: new Date(end),
      members:
        listMembers &&
        listMembers.map(u => {
          return u.id;
        }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      completed_at: completed_at,
      attachments: file && file?.filter(item => item instanceof File),
      attachmentsId:
        file &&
        file
          ?.filter(item => item.hasOwnProperty("id"))
          ?.map(a => {
            return a.id;
          }),
      resourcesIds:
        resourcesIds &&
        resourcesIds?.map(a => {
          return a.id;
        }),
    };
    const id = stateData?.controlSubmissions?.event.id || newEvent?.id;

    await axiosInstance
      .post(`event/update/${id}`, ev, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async () => {
        await dispatch(getEvents());
        dispatch(toast("success", "Event Updated Successfully"));
      })
      .catch(err => {
        dispatch(toast("error", err?.response?.data?.message));
      })
      .finally(() => {
        setEventLoader(false);
        closePopup();
      });
  };

  const removeEventAction = async () => {
    setDeleteEventLoader(true);
    const id = stateData?.controlSubmissions?.event.id || newEvent?.id;
    await axiosInstance
      .delete(`events/${id}`)
      .then(() => {
        setEvents([...events.filter(e => e.id !== id)]);
        dispatch(toast("success", "Event Removed Successfully"));
      })
      .catch(err => {
        dispatch(toast("error", err?.response?.data?.message));
      })
      .finally(() => {
        setDeleteEventLoader(false);
        closePopup();
      });
  };

  useEffect(() => {
    stateData &&
      openWithData &&
      (() => {
        titre.setNewValue(
          report_id
            ? report_title
            : `${stateData?.framework?.name || ""} ${stateData?.framework ? ":" : ""} ${stateData?.control?.name}`,
        );
        note.setNewValue(stateData?.controlSubmissions?.event?.note);
        var owner = users?.find(u => u.id === stateData?.controlSubmissions?.user_id);
        owner && setListMembers([...(stateData?.controlSubmissions?.event?.members || listMembers)]);
        setEndDate(stateData?.controlSubmissions?.event && new Date(stateData?.controlSubmissions?.event?.endTime));
        setEndHour(stateData?.controlSubmissions?.event && new Date(stateData?.controlSubmissions?.event?.endTime));
        setStartHour(stateData?.controlSubmissions?.event && new Date(stateData?.controlSubmissions?.event?.startTime));
        setStartDate(stateData?.controlSubmissions?.event && new Date(stateData?.controlSubmissions?.event?.startTime));
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData]);

  useEffect(() => {
    setListMembers(updateEvent ? newEvent?.members : []);
    setFile(updateEvent ? newEvent?.attachments : []);
    setResourcesIds(updateEvent ? newEvent?.resources || [] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEvent]);

  const handleSwitch = () => {
    var isUpdateEvent = updateEvent || (stateData?.controlSubmissions?.event && openWithData);
    isUpdateEvent ? updateEventAction() : addEventAction();
  };

  return (
    <>
      <PopupTop className={cn(styles.customTop)} />
      <div className={styles.teamsPopupContent}>
        <div className={styles.bodyPopup}>
          <div className={styles.header}>
            <Input
              className={cn(styles.inputFull, styles.info, styles.leftError)}
              type={"text"}
              value={titre.value}
              onChange={titre.onChange}
              placeholder="Event Title"
              error={errors.titre}
              noRedLine
            />
            <Select
              color={false}
              setNewValue={setNewValue}
              initValue={report_id ? "#000000" : newEvent && newEvent?.colorEvent}
              values={colors}
              className={styles.dropdown}
            />
          </div>
          <div className={styles.line} />
          {stateData && openWithData && (
            <>
              <div className={styles.input_icon}>
                <input
                  disabled={true}
                  className={styles.inputFull}
                  value={titre.value}
                />
              </div>
              <div className={styles.line} />
            </>
          )}
          <div className={styles.inputs}>
            <SvgSprite
              className={cn(styles.icon, styles.icon_place, { [styles.icon_red]: StartError })}
              spriteID={"calendar_pup"}
            ></SvgSprite>
            <div className={cn(styles.date, styles.right_line)}>
              <ReactDatePicker
                className={styles.inputFull}
                selected={start}
                selectsStart
                value={start ? start : "Add Date"}
                onChange={date => setStartDate(date)}
                startDate={start}
                endDate={end}
              />
            </div>
            <SvgSprite
              className={cn(styles.icon, styles.icon_place)}
              spriteID={"hour"}
            ></SvgSprite>
            <div className={styles.date}>
              <ReactDatePicker
                className={styles.inputFull}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm a"
                selectsStart
                selected={startHour}
                startHour={startHour}
                placeholder={startHour}
                value={startHour ? startHour : "Add Hour"}
                onChange={date => setStartHour(date)}
              />
            </div>
          </div>
          <div className={styles.line} />
          <div className={styles.inputs}>
            <SvgSprite
              className={cn(styles.icon, styles.icon_place, { [styles.icon_red]: EndError })}
              spriteID={"calendar_pup"}
            ></SvgSprite>
            <div className={cn(styles.date, styles.right_line)}>
              <ReactDatePicker
                className={styles.inputFull}
                selectsEnd
                selected={end}
                end={end}
                value={end ? end : "Add Date"}
                onChange={date => setEndDate(date)}
                startDate={start}
                endDate={end}
                minDate={start}
              />
            </div>
            <SvgSprite
              className={cn(styles.icon, styles.icon_place)}
              spriteID={"hour"}
            ></SvgSprite>
            <div className={styles.date}>
              <ReactDatePicker
                className={styles.inputFull}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="h:mm a"
                selectsEnd
                selected={endHour}
                endHour={endHour}
                placeholder={endHour}
                value={endHour ? endHour : "Add Hour"}
                onChange={date => setEndHour(date)}
              />
            </div>
          </div>
          <div className={styles.line} />
          <div
            ref={listuser}
            className={styles.addmembers}
          >
            <div className={styles.input_icon}>
              <SvgSprite
                className={styles.icon}
                spriteID={"members"}
              ></SvgSprite>
            </div>
            <Actions
              setActiveItem={setListMembers}
              listMembers={listMembers}
              isOpenedActions={openListUsers}
              actions={users}
              className={styles.btnFull}
              noIcon
              users
            />
            <Btn
              className={styles.btnFull}
              onClick={() => {
                setOpenListUsers(!openListUsers);
              }}
            >
              Add Members
            </Btn>

            <div className={styles.listProfiles}>
              {listMembers?.map((u, i) => (
                <ProfileImage
                  key={i}
                  className={styles.listMembersProfile}
                  name={u?.firstName + " " + u?.lastName}
                />
              ))}
            </div>
          </div>
          <div className={styles.line} />
          <div className={cn(styles.input_icon, styles.completedIcon)}>
            <input
              value={completed_at}
              defaultChecked={completed_at}
              onChange={e => setCompleted_at(!completed_at)}
              type="checkbox"
              className={cn(styles.checkboxInpotEvent, styles.noMargin)}
            />
            <span className={styles.spanCompleted}>Completed</span>
          </div>
          <div className={styles.line} />
          <div className={cn(styles.input_icon, styles.completedIcon)}>
            <AddAttachments
              file={file}
              setFile={setFile}
              dispatch={dispatch}
              resourcesIds={resourcesIds}
              setResourcesIds={setResourcesIds}
              dotsLoader={dotsLoader}
            />
          </div>
          <div className={styles.line} />
          <div className={styles.input_icon}>
            <SvgSprite
              className={styles.icon}
              spriteID={"pen"}
            ></SvgSprite>
            <Input
              comment={true}
              className={styles.textarea}
              type={"text"}
              value={note.value || ""}
              onChange={note.onChange}
              placeholder="Add Notes"
              rows={"2"}
              error={errors.note}
              noRedLine
            />
          </div>
        </div>
        <div className={styles.teamsPopupBtns}>
          <button
            className={cn(styles.teamsPopupBtn, styles.teamsPopupBtnBlue, {
              [styles.removeEvent]: eventLoader,
            })}
            type={"button"}
            onClick={() => handleSwitch()}
          >
            <div>
              {updateEvent || (stateData?.controlSubmissions?.event && openWithData) ? "Update Event" : "Add New"}
            </div>
            {eventLoader && <Loader />}
          </button>

          {(updateEvent || (stateData?.controlSubmissions?.event && openWithData)) && (
            <button
              className={cn(styles.teamsPopupBtn, styles.teamsPopupBtnRed, {
                [styles.removeEvent]: deletEventLoader,
              })}
              type={"button"}
              onClick={removeEventAction}
            >
              <div>Remove Event</div>
              {deletEventLoader && <Loader />}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default ReactDatePicker;
